"use strict";

import "../scss/app.scss";

import "bootstrap";
import "select2";

(function() {
    'use strict';
    window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        const validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();

                    const list = form.querySelectorAll(':invalid');
                    for (const item of list) {
                        if (item.length === 0) {
                            dataLayer.push({
                                'event': 'form.error',
                                'type': 'Formularfeld "' + item.getAttribute('data-label') + '" leer'
                            });
                        } else {
                            dataLayer.push({
                                'event': 'form.error',
                                'type': 'Formularfeld "' + item.getAttribute('data-label') + '" fehlerhaft',
                                'content': item.value
                            });
                        }
                        console.dir(item, item);
                    }
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);
})();
